import { CommonModule } from "@angular/common";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslocoModule } from "@ngneat/transloco";
import { NgxsModule } from "@ngxs/store";

import { AppInitService } from "./services/app-init.service";
import { SettingsState } from "./states/settings.state";

// extension methods have to be imported somethere or else they will not be compiled
@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule,
        RouterModule,
        TranslocoModule,
        NgxsModule.forFeature([SettingsState]),
    ],
    exports: [

    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: function OnInit(service: AppInitService) {
                return () => service.init();
            },
            deps: [AppInitService],
            multi: true,
        }
    ],
})
export class CoreModule {}
