import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { SignInCommand } from "../models/sign-in-command";
import { SignInResult } from "../models/sign-in-result";

@Injectable({ providedIn: "root" })
export class SignInService {
    constructor(private httpClient: HttpClient) {}

    public signIn(payload: SignInCommand): Observable<SignInResult> {
        return this.httpClient.post<SignInResult>("/api/account/signin", payload);
    }
}
