import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { HaUiNavigationModule } from "@ha/ui/navigation";
import { HaUtilApplicationInsightsModule } from "@ha/util/application-insights";
import { NgxsSelectSnapshotModule } from "@ngxs-labs/select-snapshot";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { NgxsStoragePluginModule } from "@ngxs/storage-plugin";
import { NgxsModule } from "@ngxs/store";

import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { routes } from "./app.routes";
import { IdentityAuthModule } from "./auth/auth.module";
import { CoreModule } from "./core/core.module";
import { SettingsState } from "./core/states/settings.state";
import { HomeModule } from "./home/home.module";
import { TranslationModule } from "./translation/translation.module";

@NgModule({
    declarations: [AppComponent],
    imports: [
        TranslationModule,
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: "ignore",
            anchorScrolling: "enabled",
            scrollPositionRestoration: "enabled",
            scrollOffset: [0, 100],
        }),
        NgxsStoragePluginModule.forRoot({
            key: [SettingsState],
        }),
        NgxsModule.forRoot([], {
            developmentMode: !environment.production,
            selectorOptions: {
                suppressErrors: false,
                injectContainerState: false,
            },
        }),
        NgxsFormPluginModule.forRoot(),
        NgxsRouterPluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.production,
        }),
        NgxsSelectSnapshotModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        HaUiNavigationModule,
        HaUtilApplicationInsightsModule,

        CoreModule,

        IdentityAuthModule,
        HomeModule
    ],
    providers: [
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
