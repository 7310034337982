import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthSelectors } from "@ha/feature/auth";
import { ModalComponent } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import { Settings } from "../../core/models/settings.model";
import { SettingsStateActions } from "../../core/states/settings.actions";
import { SettingsState } from "../../core/states/settings.state";
import { SignInResult } from "../models/sign-in-result";
import { SignIn } from "../states/sign-in.actions";
import { SignInSelectors } from "../states/sign-in.selectors";

@UntilDestroy()
@Component({
    templateUrl: "sign-in.component.html",
    styleUrls: ["sign-in.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInComponent implements OnInit {
    public redirectUri: string;

    @ViewChild("passwordHintModal") private passwordHintModal: ModalComponent;

    @Select(SettingsState.settings)
    public settings$: Observable<Settings>;

    @Select(SignInSelectors.signInResult)
    public signInResult$: Observable<SignInResult>;

    @Select(SignInSelectors.changePasswordMode)
    public changePasswordMode$: Observable<boolean>;

    @Select(AuthSelectors.isAuthenticated)
    public isAuthenticated$: Observable<boolean>;

    @Select(SettingsState.currentLanguage)
    public currentLanguage$: Observable<string>;

    @Select(SettingsState.uiLanguages)
    public uiLanguages$: Observable<string[]>;

    public form = this.fb.group({
        identity: ["", Validators.required],
        password: ["", Validators.required],
        newPassword: ["", Validators.required],
        newPasswordConfirm: ["", Validators.required],
    });

    public signInAction = SignIn;

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) { }

    public ngOnInit(): void {
        this.activatedRoute.queryParams
            .pipe(untilDestroyed(this))
            .subscribe((params) => {
                if (params.returnUrl) {
                    const queryParams = this.router.parseUrl(params.returnUrl as string).queryParams;
                    const redirectUri = queryParams["redirect_uri"] as string;
                    if (redirectUri) {
                        this.redirectUri = redirectUri;
                    }
                }
            });
    }

    public onSubmit(): void {
        this.store.dispatch(new SignIn({
            identity: this.form.get("identity").value as string,
            password: this.form.get("password").value as string,
            newPassword: this.form.get("newPassword")?.value as string,
            newPasswordConfirm: this.form.get("newPasswordConfirm")?.value as string,
            returnUrl: this.redirectUri,
        }));

        this.changePasswordMode$.pipe(untilDestroyed(this)).subscribe((yes) => {
            if (yes) {
                this.form.get("identity").disable();
                this.form.get("password").disable();
            } else {
                this.form.get("identity").enable();
                this.form.get("password").enable();

                this.form.get("newPassword").setValue(undefined);
                this.form.get("newPasswordConfirm").setValue(undefined);
            }
        });
    }

    public openPasswordHintModal(event: MouseEvent): void {
        const element = event.target as HTMLElement;
        if (element.tagName.toLowerCase() === "a") {
            this.passwordHintModal.openModal();
        }
    }

    public languageChanged(languageCode: string): void {
        this.store.dispatch(new SettingsStateActions.ChangeLanguage(languageCode));
    }
}
