import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful } from "@ngxs/store";

import { SettingsStateActions } from "./core/states/settings.actions";

@UntilDestroy()
@Component({
    selector: "identity-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
    constructor(private actions$: Actions, private cdr: ChangeDetectorRef) {}

    public ngOnInit(): void {
        // Transloco sucks workaround
        this.actions$.pipe(ofActionSuccessful(SettingsStateActions.ChangeLanguage), untilDestroyed(this)).subscribe(() => this.cdr.markForCheck());
    }
}
