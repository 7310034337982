import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HaUiCommonModule } from "@ha/ui/common";
import { HaUiFormsModule } from "@ha/ui/forms";
import { HaUiNavigationModule } from "@ha/ui/navigation";
import { HaUtilPipesModule } from "@ha/util/pipes";
import { TranslocoModule } from "@ngneat/transloco";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";
import { NgxsModule } from "@ngxs/store";

import { ApplicationPaths } from "../constants/application-paths";
import { SignInComponent } from "./components/sign-in.component";
import { SignOutComponent } from "./components/sign-out.component";
import { SignInState } from "./states/sign-in.state";

@NgModule({
    imports: [
        CommonModule,
        TranslocoModule,
        NgxsFormPluginModule,
        HaUiCommonModule,
        HaUiFormsModule,
        HaUtilPipesModule,
        HaUiNavigationModule,

        NgxsModule.forFeature([SignInState]),
        RouterModule.forChild([
            { path: ApplicationPaths.SignIn, component: SignInComponent },
            { path: ApplicationPaths.SignOut, component: SignOutComponent },
        ]),
    ],
    declarations: [SignInComponent, SignOutComponent],
    exports: [
        SignInComponent,
        SignOutComponent
    ],
    providers: [
    ],
})
export class IdentityAuthModule { }
