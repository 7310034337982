import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { produce } from "immer";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { SignInCommand } from "../models/sign-in-command";
import { SignInResult, SignInStatus } from "../models/sign-in-result";
import { SignInService } from "../services/sign-in.service";
import { SignIn } from "./sign-in.actions";

export interface SignInStateModel {
    signIn: {
        form: {
            model: SignInCommand;
            dirty: boolean;
            status: string;
            errors: never;
        };
        result: SignInResult;
    };
}

@Injectable()
@State<SignInStateModel>({ name: "signIn" })
export class SignInState {
    constructor(private signInService: SignInService) { }

    @Action(SignIn)
    public signIn(ctx: StateContext<SignInStateModel>, action: SignIn): Observable<SignInResult> {
        return this.signInService.signIn(action.payload).pipe(tap((result) => {
            ctx.setState(produce(ctx.getState(), (draft) => {
                draft.signIn = draft.signIn ?? {
                    form: undefined,
                    result: undefined,
                };

                draft.signIn.result = result;
            }));

            if (result.status === SignInStatus.Success) {
                if (action.payload.returnUrl) {
                    location.href = action.payload.returnUrl;
                } else {
                    location.href = "/";
                }
            }
        }));
    }
}
