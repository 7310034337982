import { Injectable } from "@angular/core";
import { globalEnvironment } from "@ha/constants";
import { Translation, TranslocoLoader } from "@ngneat/transloco";
import { forkJoin, from, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class TranslocoFileLoaderService implements TranslocoLoader {
    private globalFiles = globalEnvironment.globalI18nFiles;

    public getTranslation(langPath: string): Observable<Translation> {
        const toAppPath = (file: string) => from(import(`apps/identity/src/i18n/${file}${langPath}.json`));
        const toGlobalPath = (file: string) => from(import(`global-i18n/${file}${langPath}.json`));

        const files = [...this.globalFiles.map((_) => toGlobalPath(_ + ".")), toAppPath("")];

        return forkJoin(files).pipe(map((_) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            return Object.assign({}, ..._) as Translation;
        }));
    }
}
