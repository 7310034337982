import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AuthSelectors } from "@ha/feature/auth";
import { Application } from "@ha/ui/navigation";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { SettingsState } from "../../core/states/settings.state";

@Component({
    templateUrl: "./home.component.html",
    styleUrls: ["home.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent {
    @Select(AuthSelectors.isAuthenticated)
    public isAuthenticated$: Observable<boolean>;

    @Select(SettingsState.applications)
    public applications$: Observable<Application[]>;
}
