import { NgModule } from "@angular/core";
import { TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoModule } from "@ngneat/transloco";

import { environment } from "../../environments/environment";
import { TranslocoFileLoaderService } from "./transloco-file-loader.service";

@NgModule({
    imports: [
        TranslocoModule
    ],
    exports: [
        TranslocoModule
    ],
    providers: [
        { provide: TRANSLOCO_LOADER, useClass: TranslocoFileLoaderService },
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: ["en", "sv"],
                defaultLang: "sv",
                fallbackLang: ["en", "sv"],
                reRenderOnLangChange: true,
                prodMode: environment.production,
                missingHandler: {
                    useFallbackTranslation: true
                }
            }),
        }
    ],
})
export class TranslationModule {}
