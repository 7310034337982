<div class="sign-in">
    <ng-container *transloco="let t; read: 'authorize.signIn'">
        <logo class="sign-in__logo"></logo>

        <ui-language class="root__language" [horizontal]="true" [lightBackground]="true" [languages]="uiLanguages$ | async"
                     [currentLanguage]="currentLanguage$ | async" (languageChanged)="languageChanged($event)">
        </ui-language>

        <form class="sign-in__form" [formGroup]="form" (ngSubmit)="onSubmit()" [ngxsFormDebounce]="0" ngxsForm="authorize.signInForm">
            <div class="sign-in__form__hint" [innerHTML]="t('usernameHint')"></div>
            <ha-input type="email" class="sign-in__form__input" formControlName="identity" [label]="t('identity')" autocomplete="username"
                      [readOnlyOnDisabled]="true">
            </ha-input>
            <ha-input type="password" class="sign-in__form__input" formControlName="password" [label]="t('password')" autocomplete="current-password"
                      [readOnlyOnDisabled]="true">
            </ha-input>

            <ng-container *ngIf="signInResult$ | async as signInResult">
                <div *ngIf="signInResult.status > 1 && (changePasswordMode$ | async) === false" class="sign-in__form__error-message">
                    {{ t("status." + signInResult.status, { lockoutEndDate: signInResult.lockoutEndDate | haDate: "time" }) }}
                </div>

                <ng-container *ngIf="changePasswordMode$ | async">
                    <div (click)="openPasswordHintModal($event)" class="sign-in__form__info-message" [innerHtml]="t('passwordExpiredMessage')">
                    </div>
                    <ha-input type="password" class="sign-in__form__input" formControlName="newPassword" [label]="t('newPassword')" autocomplete="new-password">
                    </ha-input>

                    <ha-input type="password" class="sign-in__form__input" formControlName="newPasswordConfirm" [label]="t('newPasswordConfirm')"
                              autocomplete="new-password">
                    </ha-input>

                    <div *ngIf="signInResult.status > 1 && signInResult.status !== 5" class="sign-in__form__error-message">
                        {{ t("status." + signInResult.status, { lockoutEndDate: signInResult.lockoutEndDate | haDate: "time" }) }}
                    </div>
                </ng-container>
            </ng-container>

            <button class="sign-in__form__button" type="submit" [loading]="signInAction">{{ t("submit") }} </button>

        </form>

        <modal #passwordHintModal [closeLabel]="t('close')">
            <div [innerHTML]="t('passwordHelpText')"></div>
        </modal>

        <ng-container *ngIf="(signInResult$ | async)?.status !== 5">
            <div class="sign-in__forgot-password">
                <a [href]="(settings$ | async)?.appUri + '/forgot'" class="sign-in__forgot-password__anchor">{{ t("forgotPassword") }}</a>
            </div>

            <div class="sign-in__activate">
                <div class="sign-in__activate__header">{{ t("activateHeader") }}</div>
                <a [href]="(settings$ | async)?.appUri + '/activate'" class="sign-in__activate__anchor">{{ t("activateAccount") }}</a>
            </div>
        </ng-container>
    </ng-container>
</div>
