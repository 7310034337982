import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";

import { SettingsStateActions } from "../states/settings.actions";

@Injectable({ providedIn: "root" })
export class AppInitService {
    constructor(private store: Store) {}

    public init() {
        this.store.dispatch(new SettingsStateActions.GetSettings());
    }
}
