<div class="root" *transloco="let t">
    <h1 class="root__heading" [innerHTML]="t('welcome')"></h1>

    <!-- <div class="root__applications" *ngFor="let application of (applications$ | async); let i = index">
        <a *transloco="let t; read: 'applications'" class="puff" [href]="application.baseUrl">
            <div class="puff__header">
                <div class="puff__header__icon" [class]="'puff__header__icon--' + application.internalIdentifier"></div>
                <div class="puff__header__heading" [innerHTML]="t(application.internalIdentifier + '.name')"></div>
            </div>

            <div class="puff__text" [innerHTML]="t(application.internalIdentifier + '.description')"></div>

            <div class="puff__bottom">
                <div class="puff__bottom__line"></div>
                <div class="puff__bottom__arrow" [class]="'puff__bottom__arrow--' + application.internalIdentifier"></div>
            </div>
        </a>
    </div> -->

    <a *ngFor="let application of applications$ | async" class="puff" [href]="application.baseUrl">
        <ng-container *transloco="let t; read: 'ui.navigation.applications.' + application.internalIdentifier">
            <div class="puff__header">
                <div class="puff__header__icon" [class]="'puff__header__icon--' + application.internalIdentifier"></div>
                <div class="puff__header__heading" [innerHTML]="t('name')"></div>
            </div>

            <div class="puff__text" [innerHTML]="t('description')"></div>
            
            <div class="puff__bottom">
                <div class="puff__bottom__line"></div>
                <div class="puff__bottom__arrow" [class]="'puff__bottom__arrow--' + application.internalIdentifier"></div>
            </div>
        </ng-container>
    </a>
</div>
