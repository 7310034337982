import { Selector } from "@ngxs/store";

import { SignInResult, SignInStatus } from "../models/sign-in-result";
import { SignInState, SignInStateModel } from "./sign-in.state";

export class SignInSelectors {
    @Selector([SignInState])
    public static signInResult(state: SignInStateModel): SignInResult {
        return state.signIn?.result;
    }

    @Selector([SignInState])
    public static changePasswordMode(state: SignInStateModel): boolean {
        const status = state?.signIn?.result?.status;
        return status === SignInStatus.PasswordExpired || status === SignInStatus.CannotReuseOldPassword;
    }
}
