import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TranslocoModule } from "@ngneat/transloco";

import { HomeComponent } from "./components/home.component";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        component: HomeComponent,
    }
];

@NgModule({
    declarations: [HomeComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        RouterModule.forChild(routes),
    ],
})
export class HomeModule {}
