export interface SignInResult {
    status: SignInStatus;
    lockoutEndDate: string | null;
}

export enum SignInStatus {
    Success = 1,
    Failed = 2,
    IsLockedOut = 3,
    IsBanned = 4,
    PasswordExpired = 5,
    CannotReuseOldPassword = 6,
}
